<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="标题名称" prop="tagTitle" >
        <a-input v-model="form.tagTitle" placeholder="请输入标题名称" />
      </a-form-model-item>
<!--      <a-form-model-item label="封面图" prop="coverUrl" >-->
        <!--        <a-input v-model="form.coverUrl" placeholder="请输入封面图" />-->

        <!--          :customRequest="handleUpload"-->
<!--        <a-upload-->
<!--            listType="picture-card"-->
<!--            class="avatar-uploader"-->
<!--            v-model="form.imgUrl"-->
<!--            :showUploadList="false"-->
<!--            action="https://web.frameflash.com/admin-api/common/local/file/uploadCos"-->
<!--            :beforeUpload="beforeUpload"-->
<!--            :customRequest="handleChange"-->
<!--        >-->
<!--          <img v-if="form.imgUrl" :src="form.imgUrl" alt="logo"-->
<!--               style="height:104px;max-width:300px"/>-->
<!--          <div v-else>-->
<!--            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>-->
<!--            <div class="ant-upload-text">上传</div>-->
<!--          </div>-->
<!--        </a-upload>-->
<!--      </a-form-model-item>-->
      <a-form-model-item style="display: flex" label="颜色" prop="colors" >
        <el-color-picker style="margin-left: 100%"  v-model="form.colors" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTag, addTag, updateTag } from '@/api/video/tag'
import elColorPicker from 'el-color-picker'
import UploadFileToVOD from "@/api/vod.js"
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    elColorPicker
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      uploadLoading: false,
      // 表单参数
      form: {
        id: null,

        tagTitle: null,

        colors: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        tagTitle: [
          { required: true, message: '标题名称不能为空' }
        ],
        colors:[
          { required: true, message: '请选择颜色' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.form={
             id: null,

            tagTitle: null,

            colors: null,

            createTime: null,

            remark: null,

      }
    },

    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true;
        return;
      }
      let that = this
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          debugger
          console.log(fileName)
          that.form.imgUrl = fileName[0];//文件名
        }
      });


    },
    beforeUpload: function (file) {
      var fileType = file.type;
      if (fileType.indexOf('image') < 0) {
        this.$message.warning('请上传图片');
        this.uploadLoading=false

        return false;
      }
      this.form.imgUrl=""
      //TODO 驗證文件大小
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        tagTitle: null,
        colors: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.form.colors = "#d70b0b"
      this.form.tagTitle = ""
      // this.form={
      //   id: null,
      //
      //   tagTitle: null,
      //
      //   colors: null,
      //
      //   createTime: null,
      //
      //   remark: null,
      //
      // }
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTag({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTag(this.form).then(response => {
              if (response.code==20001) {
                this.$message.success(
                  '修改成功',
                  3
                )
              }
              this.open = false
              this.$emit('ok')
              this.form={
                id: null,

                tagTitle: null,

                colors: null,

                createTime: null,

                remark: null,

              }
            }).finally(() => {
              this.submitLoading = false
              this.open = false
              this.form={
                id: null,

                tagTitle: null,

                colors: null,

                createTime: null,

                remark: null,

              }
            })
          } else {
            addTag(this.form).then(response => {
              if (response.code==20001) {
                this.$message.success(
                  '新增成功',
                  3
                )
              }

              this.open = false
              this.$emit('ok')
              this.form={
                id: null,

                tagTitle: null,

                colors: null,

                createTime: null,

                remark: null,

              }
            }).finally(() => {
              this.submitLoading = false
              this.open = false
              this.form={
                id: null,

                tagTitle: null,

                colors: null,

                createTime: null,

                remark: null,

              }
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
